import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
// import { Home } from "screens";
import { Navbar, Footer, PageNotFound } from "components";
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
      {/* <Footer /> */}
      <Analytics/>
    </Router>
  );
}

export default App;
